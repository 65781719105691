<template>
  <App
    title="分配"
    :border="false"
    left-arrow
  >
    <div class="full-container">
      <SelectCell
        v-if="this.$route.query.Distribution !== '2'"
        v-model="distributeValidate.distribute"
        :options="options"
        label='分配对象'
        is-link
        @input="handleinput"
      />
      <SelectCell
        v-if="distributeValidate.distribute === '1' "
        v-model="distributeValidate.region"
        :options="regionList"
        label='大区'
        is-link
        @choose="choose"
      />
      <SelectCell
        v-if="distributeValidate.distribute === '2'"
        v-model="distributeValidate.district"
        :options="districtList"
        label='小区'
        is-link
        @choose="choose"
      />

      <SelectCell
        v-if="distributeValidate.distribute === '3'"
        v-model="distributeValidate.store"
        :options="storeList"
        label='门店'
        is-link
        @choose="choose"
      />
      <SelectCell
        v-if="distributeValidate.distribute === '4'"
        v-model="distributeValidate.counselor"
        :options="counselorList"
        label='出行顾问'
        is-link
        @choose="choose"
      />
      <!-- 淘客池 -->
      <SelectCell
        v-if="this.$route.query.Distribution === '2' "
        :options="salesConsultant"
        label='销售顾问'
        @input="handleinput"
        is-link
        @choose="choose"
      />
      <!-- <div class="button">
        <Button type="default"
                class="end"
                @click="escback">取消</Button>
        <Button type="info"
                class="sure"
                @click="handleclick">确认</Button>
      </div> -->
      <FooterBtn
        @cancel='onNavBarClickLeft'
        @ok='onNavBarClickRight'
      ></FooterBtn>
    </div>
  </App>
</template>
<script>
import SelectCell from '_c/dynamicForm/selectCell'
import FooterBtn from '_c/footerBtn/index'
import { Toast } from 'vant'
import { organization, userserList, leadssend, oppsend, userlist, leadstksend } from '@/api/chargequery'
export default {
  name: 'taoGuestPoolAllot',
  components: {
    SelectCell,
    FooterBtn
  },
  data () {
    return {
      value: '',
      isshow: '',
      options: [],
      regionList: [],
      districtList: [],
      storeList: [],
      counselorList: [],
      salesConsultant: [], // 销售顾问
      code: '',
      distributeValidate: {
        distribute: '',
        store: '',
        region: '',
        district: '',
        counselor: ''
      },
      orgCode: ''
    }
  },
  methods: {
    init () {
      this.orgCode = this.$storage.get('orgCode')
      const allotsendtotype = this.$storage.get('baseData').allot_send_to_type
      this.options = allotsendtotype
      try {
        if (this.$route.query.Distribution === '0') {
          //线索分配显示全部
          if (this.Code === 'RE002') {
            this.options = allotsendtotype
          }
          if (this.Code === 'RE003') {
            this.options = allotsendtotype.filter(item => {
              if (item.value !== '1') {
                return item
              }
            })
          }
          if (this.Code === 'RE004') {
            this.options = allotsendtotype.filter(item => {
              if (item.value !== '1' && item.value !== '2') {
                return item
              }
            })
          }
          if (this.Code === 'RE005') {
            this.distributeValidate.distribute = '4'
            this.options = allotsendtotype.filter(item => {
              if (item.value !== '1' && item.value !== '2' && item.value !== '3') {
                return item
              }
            })
          }
        }
        if (this.$route.query.Distribution === '1') {
          // 商机分配显示---门店，MC
          allotsendtotype.splice(0, 2)
          this.options = allotsendtotype
        }
      } catch (e) { console.log(e) }
      this.options.forEach(item => {
        item.label = item.key
      })
      this.organization()
      this.getuserserList()
      // this.userlist()
      this.getStoreList()
    },
    // 销售顾问
    async userlist () {
      const res = await userlist({
        orgCode: this.$storage.get('orgCode')
      })
      if (res.code === '000000') {
        this.salesConsultant = res.data.Data.map(item => {
          return { label: item.accountName, code: item.accountNo }
        })
      } else {
        Toast(res.msg)
      }
    },
    // 出行顾问
    async getuserserList () {
      const { data } = await userserList({ orgCode: this.$storage.get('orgCode') }) // 先写固定数据
      this.counselorList = data
      this.counselorList.forEach(item => {
        item.label = item.name
        item.code = item.idmUserid
      })
      this.salesConsultant = data.map(item => {
        return { label: item.name, code: item.idmUserid }
      })
    },
    // 门店
    getStoreList () {
      this.storeList = this.$storage.get('userStore').map(el => {
        return { code: el.code, label: el.name }
      })
    },
    // 导入数据
    async organization () {
      const { data } = await organization({ orgcode: this.$storage.get('orgCode') })
      this.convertTree(data)
    },
    convertTree (tree) {
      // 获取对应当角色code下的大区小区
      tree.forEach(item => {
        // 如果是总部就那全部数据，否则拿对应角色下的数据
        if (this.orgCode === 'HQ0001') {
          // 大区
          if (item.data.type === '2') {
            this.regionList.push({ code: item.data.code, label: item.data.name })
          }
          // 小区
          if (item.data.type === '3') {
            this.districtList.push({ code: item.data.code, label: item.data.name })
          }
        } else {
          // 大区
          if (item.data.type === '2' && item.data.parentCode === this.orgCode) {
            this.regionList.push({ code: item.data.code, label: item.data.name })
          }
          // 小区
          if (item.data.type === '3' && item.data.parentCode === this.orgCode) {
            this.districtList.push({ code: item.data.code, label: item.data.name })
          }
        }

        this.convertTree(item.children)
      })
    },
    handleinput (val) {
      this.isshow = val
    },
    // 取消按钮
    onNavBarClickLeft () {
      this.$router.go(-1)
    },
    choose (v) {
      this.code = v.code
    },
    //确认
    onNavBarClickRight () {
      if (!this.code) return Toast('请选择')
      //有值说明跳转过来的
      try {
        if (this.$route.query.Distribution) {
          this.getData()
        }
      } catch (e) {
        Toast('请从任意下发分配中选择分配')
      }

    },
    async getData () {
      //线索下发分配
      if (this.$route.query.Distribution === '0') {
        console.log(this.code, 'this.code', this.distributeValidate.distribute)
        const res = await leadssend({
          leadesAssignmentHistoryId: this.$route.query.eosLeadesAssignmentHistoryId,
          areashopTypeTo: this.distributeValidate.distribute,//---选择
          areashopIdTo: this.code
        })
        if (res.code === '000000') {
          Toast('分配成功')
          this.onNavBarClickLeft()
        } else {
          Toast(res.msg)
        }
      }
      //商机下发分配
      if (this.$route.query.Distribution === '1') {
        const res = await oppsend({
          eosOppAssignmentHistoryId: this.$route.query.eosOppAssignmentHistoryId,
          areashopTypeTo: this.distributeValidate.distribute,
          areashopIdTo: this.code
        })
        if (res.code === '000000') {
          Toast('分配成功')
          this.onNavBarClickLeft()
        } else {
          Toast(res.msg)
        }
      }
      // 淘客池分配
      if (this.$route.query.Distribution === '2') {
        const res = await leadstksend({
          leadsId: this.$route.query.eosLeadsId,
          areashopIdTo: this.code,
          areashopTypeTo: '4' // 销售顾问为4
        })
        if (res.code === '000000') {
          Toast('分配成功')
          this.onNavBarClickLeft()
        } else {
          Toast(res.msg)
        }
      }
    },
  },
  mounted () {
    this.Code = this.$storage.get('roleCode')
    this.init()
  }
}
</script>
<style scoped>
.button {
  padding: 0 5% 2%;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 6%;
}
.sure {
  height: 70%;
  width: 65%;
  margin-left: 5%;
  background: url("./button.png") no-repeat;
  background-size: 100%;
}
.end {
  height: 70%;
  width: 20%;
  /* border: 2px skyblue solid; */
  color: rgb(6, 176, 243);
}
.sure,
.end {
  border-radius: 60px;
}
</style>